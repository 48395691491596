<template>
    <div class="notification-list">
      <el-row :gutter="20">
        <el-col :span="24">
            <el-tabs type="border-card">
              <el-tab-pane :label="$t('data.notification.title')">
                <el-table
                  :data="tableData"
                  style="width: 100%">
                  <el-table-column
                  prop="title"
                  :label="$t('data.notification.event')">
                  </el-table-column>
                  <el-table-column
                  prop="created_at"
                  :label="$t('data.notification.time')">
                  </el-table-column>
                  <el-table-column
                    align="right"
                    :label="$t('data.public.action')">
                    <template #default="scope">
                        <el-button 
                            @click="toDetails(scope.$index, scope.row)" 
                            type="primary" size="small" 
                            v-if="(scope.row.type === 2) ? false : true">
                                <!-- {{ $t('data.notification.details') }} -->
                                <!-- {{scope.row.message}} -->
                                {{ $t('data.notification.details') }}
                            </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <table-pagination :page="page" @updatePage="updatePage"></table-pagination>
              </el-tab-pane>
            </el-tabs>
        </el-col>
      </el-row>
        <!-- 系统通知提示 -->
        <el-dialog
            :title="systemInfo.title"
            v-model="dialogVisible"
            width="30%"
            >
            <span>{{systemInfo.message}}</span>
        </el-dialog>
    </div>
</template>

<script>
import tablePagination from "@/components/tablePagination/TablePagination"
import { branchNotifications,notificationsUnreadCount } from '../../api/index'
export default {
    name: 'notificationList',
    components:{
        tablePagination
    },
    data() {
        return {
            tableData: [],
            page: {},
            dialogVisible: false,
            systemInfo: {}
        }
    },
    mounted(){
        this.initPageData();
    },
    methods: {
        initPageData(data){
            let apiData = {}
            if(data){
                Object.assign(apiData,data)
            }
            branchNotifications({apiData}).then( res => {
                this.tableData = res.data;
                this.page = res.meta.page;
                this.updateUnreadCount();
            }) 
        },
        toDetails(index, row){
            if(row.type === 3){
                this.$router.push('/ecert/detail/'+row.modelable_id);
            } else if(row.type === 1){
                this.systemInfo = row;
                this.dialogVisible = true;
            }
        },
        updatePage(data){
            this.initPageData(data)
        },
        updateUnreadCount(){
            notificationsUnreadCount({}).then( res => {
                this.$store.commit("hadndleNotification", res.data.count);
            })
        }
    }
}
</script>

<style scoped>

</style>